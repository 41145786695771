import React from "react";
import { IconContext } from "react-icons";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./src/styles/global.css";
import "@fontsource-variable/urbanist";
import "@fontsource-variable/kumbh-sans";

const WrapWithProviders = ({ children }) => (
  <IconContext.Provider
    value={{
      style: { verticalAlign: "middle" },
      className: "global-class-name",
    }}
  >
    {children}
  </IconContext.Provider>
);

export default WrapWithProviders;
